import React from "react"
import "./OcrSeparator.scss"
import Logo from "../Logo"

const Image = (props) => {
  return (
    <div className="separator">
      <div className="line" />
      <Logo height={100} />
      <div className="line" />
    </div>
  )
}

export default Image
