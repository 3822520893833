import React from "react"
import "./Camp.scss"
import HeroBanner from "../../components/HeroBanner"
import Layout from "../../components/Layout"
import { graphql, Link } from "gatsby"
import { ListGroup, ListGroupItem } from "reactstrap"
import OcrSeparator from "../../components/OcrSeparator"

const Camp = ({ data }) => {
  const items = data.allWordpressWpCampDay.edges.map((item) => {
    return (
      <ListGroupItem key={item.node.id}>
        <strong>{item.node.acf.campday}</strong> - {item.node.acf.availability}
      </ListGroupItem>
    )
  })

  return (
    <Layout>
      <div className="Team">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="OCRA Ninja Camp"
          caption="The best camp in the city"
          actionURL="#availability"
          actionText="Spaces Available"
        />
        <div className="container content">
          <h2>OCRA Full Day Camp</h2>
          <p>
            Our full day weekly camp programs will begin on August 3rd and will
            be offered for the rest of the summer. At this time full-day camp
            will be offered to children who are between ages 7-13 yrs old.
            Campers who were already registered in camp will be given priority.
            To register please email{" "}
            <a href="mailto:info@painistemporary.net">
              info@painistemporary.net
            </a>{" "}
            with the title being “Register my kid for camp!" in the title,
            please indicate your child’s name. A staff member will contact you
            to complete your registration.
          </p>

          <OcrSeparator />
          <h2>How will it work?</h2>
          <p>
            OCRA full day camp will offer kids an escape from home for a day
            where they can run, jump, climb and swing on our indoor obstacle
            course. We will be following all necessary local and provincial
            guidelines for Day Camps, including a total of no more than 20 kids.
            The kids will be broken into 2 groups of 10 and be encouraged to
            maintain their social distancing.
          </p>

          <OcrSeparator />
          <h2>All programs run Monday – Friday and start at $285/week</h2>
          <p>
            Programs will continue to be added and adjusted depending on
            provincial and local health regulations. Full-day camps will be
            added in August pending government approval.
          </p>

          <OcrSeparator />
          <h2>Important program info</h2>
          <p>Drop off and Pick up locations:</p>
          <ul>
            <li>
              Drop off/pick up, parents and children are to wait in their cars
              until a staff member directs them into the gym, where they will be
              screened with 4 questions:
              <ul>
                <li>
                  Have you or anyone in your household had the following
                  symptoms over the last 24 hrs?
                </li>
                <li>Fever over 100.4</li>
                <li>Shortness of breath</li>
                <li>Coughing</li>
                <li>
                  Have you or anyone in your family worked in close proximity
                  with or on someone with confirmed Covid-19 in the last 14
                  days?
                </li>
              </ul>
            </li>
            <li>
              It is the responsibility of the parents to take each child's
              temperature before they arrive to camp.
            </li>
            <li>
              Managers will be present to supervise drop off and pick up and to
              help direct parents and campers
            </li>
            <li>
              Everyone will enter the same door and will use the Exit door on
              the other side of the building, both are facing East.
            </li>
            <li>
              Parents will not be permitted to enter the facility and the child
              will be signing themselves in at the door. For pick up please
              enter the front of the building and we will notify your child that
              you are here and they will leave on separate entrance, no parents
              are permitted in the gym.
            </li>
            <li>
              Registration will be available online only. Please ensure you have
              a complete and accurate family account. To register please fill
              out a waiver filled out on our website{" "}
              <a href="https://www.ocracademy.ca/waiver">
                https://www.ocracademy.ca/waiver
              </a>
            </li>
          </ul>

          <OcrSeparator />
          <h2>Health & Safety:</h2>
          <ul>
            <li>
              Campers and staff will be screened prior to arriving on site.
            </li>
            <li>
              Those who have indicated that they or a member of the same
              household has travelled outside the country in the past 14 days
              will not be permitted into the program.
            </li>
            <li>
              Campers or staff who are exhibiting symptoms of illness may not be
              permitted into the facility. Those who exhibit signs or symptoms
              of illness during the program will be isolated from the group and
              sent home immediately. Others in the group will be notified
              immediately.
            </li>
            <li>
              All participants must meet the minimum age requirement of 7 years.
              At this time, there are no camps offered for younger children but
              this may be offered in the later months.
            </li>
            <li>
              Children will only be permitted to register for one session each
              week. If a child registers for a morning program and then an
              afternoon program during the same week, the second registration
              will be cancelled by OCR Academy and that spot released to a child
              on the waitlist.
            </li>
            <li>
              Children will be asked to not register with any other organization
              within 14 days of their participation at OCR Academy. For the
              safety of the entire community, it will be important that a child
              does not attend the evening session at OCR Academy, after spending
              the day in another program or summer camp.
            </li>
          </ul>

          <OcrSeparator />
          <h2>Program Details:</h2>
          <ul>
            <li>
              Day camps will run from 8:30 am - 4:00 pm with a 40 minute lunch)
              and there will be an hour of outdoor time.
            </li>
            <li>
              Items from home will be limited to:
              <ul>
                <li>
                  1 water bottle, filled to the top at the time of drop off. We
                  do have OCRA bottles for sale if they are needed. A refill is
                  offered but will be handled buy the staff.
                </li>
                <li>Snacks and a lunch that does not require refrigeration.</li>
                <li>
                  Sun hat, and one bottle of sunscreen that the child is
                  comfortable applying themselves. Leaders will assist using
                  verbal cues only. All items must be labelled with the child’s
                  full name. NO other items will be permitted in the group’s
                  activity space, unless deemed appropriate and/or necessary by
                  Management.
                </li>
              </ul>
            </li>
            <li>
              Each group will have one designated Group Leader and one
              designated Activity Leader. Each team of 2 will work together to
              ensure a safe, high-quality program for each child.
            </li>
            <li>
              Each full day camp will include indoor activities, such as
              traditional fun games like “Floor is lava”, but with an OCR twist,
              outdoor activities, and free time where each group can play on a
              section of the course for X amount of time and they switch.
              (pending City’s approval and following specific disinfecting
              guidelines between groups).
            </li>
          </ul>

          <OcrSeparator />
          <h2>Registration Details:</h2>
          <p>
            Those with priority registration who were already registered for
            summer camp with OCR Academy will be given priority.
          </p>
          <p>Before registration, please note:</p>
          <ul>
            <li>
              Registration will be available online only. Please ensure you have
              a complete and accurate family account. To register please fill
              out a waiver filled out on our website
              https://www.ocracademy.ca/waiver once that is completed please
              email us with the title “Camp Registration for August”
            </li>
            <li>
              All campers must meet the minimum age requirement of 7 years old.
              There are no exceptions.
            </li>
            <li>
              Children will be asked to not register with any other organization
              within 14 days of their OCR Academy Day Camp. For the safety of
              the entire community it will be important that a child does not
              attend the afternoon session at OCR Academy, after spending the
              day in another program or summer camp.
            </li>
          </ul>

          <OcrSeparator />
          <h2 id="availability">Availability</h2>
          <ListGroup>{items}</ListGroup>

          <OcrSeparator />
          <h2>Questions & Answers</h2>
          <div className="indented">
            <p>
              <strong>1. My child is 6.5, why can’t I register him/her?</strong>
            </p>

            <p>
              We do not have the resources or the space to accomedate a wide age
              range of kids, We feel that children aged between 7-11 posses a
              lot of the independence we require from our campers, and are close
              enough in age where we they can interact with one another. With
              out the ability to physically interact with a child we are not
              properly equipped to help children manage on their own, especially
              with things like accidents, self-care, and applying sunscreen.
            </p>

            <p>
              <strong>
                2. Will masks or other protective equipment be required?
              </strong>
            </p>

            <p>
              We will be following the provincial and local health
              recommendations and updating PPE policies as things progress. At
              this time, masks will be optional for participants and staff. We
              recommend that children who are comfortable wearing them to bring
              their own, but wearing masks will not be enforced by staff, nor
              will staff be assisting children with their masks. The Ministry of
              Health has recommended that any staff or participant wear a mask
              if or when they need to go outside of their “bubble”.
            </p>

            <p>
              <strong>
                3. What happens if someone in my child’s group exhibits symptoms
                of COVID?
              </strong>
            </p>

            <p>
              Children exhibiting symptoms will be removed from the group and
              will wait with a leader to be picked up by a parent. Parents of
              the other children in the group will be phoned immediately and the
              situation will be assessed from there with the assistance of
              Public Health Authorities. Should there be immediate cause for
              concern, the program may be cancelled for the duration of that
              week, and participants asked to self-isolate according to OPH
              recommendations.
            </p>

            <p>
              <strong>4. How do I get Priority Registration?</strong>
            </p>

            <p>
              If you were registered for a camp, had not withdrawn or requested
              to be withdrawn, please email{" "}
              <a href="mailto:info@painistemporary.net">
                info@painistemporary.net
              </a>{" "}
              with your child(ren) name and what week they were registered for.
            </p>
          </div>

          <OcrSeparator />
          <h2>Medications:</h2>

          <p>
            All prescribed medication must be kept in the original package
            (box/bottle, etc) including a pharmacist label indicating proper
            dosage instructions and the child’s name. A medication authorization
            form must also be filled out and signed by a parent before any child
            is given medication. These forms can be printed and brought with you
            on the first day of camp or filled out in the Camp Office when you
            arrive. A form is required for all medication provided even if not
            administered (Epi-Pens).
          </p>

          <p>
            All medications must be brought into the Camp Office by a parent
            with the correct form attached. At the end of the day, or end of the
            week, we ask that you sign it out again to ensure that we have
            accurate records of all medications currently stored on-site.
          </p>

          <p>
            If your child is going off-site, his or her Camp Leader will sign
            the medication out of the office and bring it with them for the
            trip, returning it to the office again once the camp group arrives
            on-site.
          </p>

          <p>
            Epi-Pens must be worn by the camper during the day in a pouch or
            fanny pack. Staff will not carry Epi-Pens for campers and they
            should not be placed in backpacks. If your child is bringing a
            second Epi-Pen, we will store it at the front desk for quick access.
            Please speak to Camp Manager if you have any concerns or to discuss.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Camp

export const query = graphql`
  query {
    allWordpressWpCampDay(sort: { order: ASC, fields: order }) {
      edges {
        node {
          id
          acf {
            campday
            availability
          }
        }
      }
    }
    file(
      url: {
        eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/08/academy-small.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
