import React from 'react'
import "./Logo.scss"

const Logo = props => {
  const { stroke, fill, height } = props

  const style = { height: `${height}px` }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 423.75 701.73" stroke={stroke} fill={fill} style={style}>
      <title>OCR_logo-2019_v2.2</title>
      <polygon points="19.66 161.89 113 452.49 107.82 218.96 149.33 228.03 176.58 148.92 211.01 594.21 244.03 148.92 269.97 228.03 314.08 218.96 307.59 452.49 403.54 157.99 277.75 209.88 211.01 34.75 144.14 207.29 19.66 161.89"/><polygon class="a" points="169.89 201.15 156.35 240.51 118.81 232.3 125.33 525.78 2.79 144.36 137.95 193.66 211.1 4.93 283.83 195.81 420.88 139.27 294.85 526.08 303.02 232.15 262.78 240.43 250.69 203.55 211.15 701.58 169.89 201.15"/>
    </svg>
  )
}

export default Logo
